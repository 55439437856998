<template>
  <section class="edit-category">
    
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12">
                <h3 class="page-title float-left">PayPal</h3>

                <router-link class="float-right" to="/settings/config-settings">
                  <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="editPaypal">
              <div class="row" v-if="info">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal Currency	</label>
                    <input type="text" v-model="info.PAYPAL_CURRENCY"
                    class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal Live Client Id</label>
                    <input type="text" v-model="info.PAYPAL_LIVE_CLIENT_ID" 
                    class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name"> PayPal Locale</label>
                    <input type="text" v-model="info.PAYPAL_LOCALE"
                    class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal Live Client Secret</label>
                    <input type="text" v-model="info.PAYPAL_LIVE_CLIENT_SECRET"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal SandBox Client Id	</label>
                    <input type="text" v-model="info.PAYPAL_SANDBOX_CLIENT_ID"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal SandBox Mode</label>
                    <input type="text" v-model="info.PAYPAL_SANDBOX_MODE"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal SandBox Client Secret</label>
                    <input type="text" v-model="info.PAYPAL_SANDBOX_CLIENT_SECRET"
                     class="form-control"/>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">PayPal Notify URL</label>
                    <input type="text" v-model="info.PAYPAL_NOTIFY_URL"
                     class="form-control"/>
                  </div>
                </div>
                
                <div class="col-md-12">
                  <div class="form-group float-right">
                    <button class="btn btn btn-add btn-primary">Update</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";

export default {
  name: "edit-paypal",

  components: {
    
  },
  data() {
    return {
      info: null,
      
    };
  },
 mounted() {
    this.list();
  },
  methods: {
    async list() {
      
      const { data } = await setingsApi.paypal();
      this.info = data.data.data;
      
    },
    async editPaypal() {

      for (var key in this.info) {
        if (this.info.hasOwnProperty(key)) {
          var val = this.info[key];
          if (val === null || val === "") {
            delete this.info[key];
          }
        }
      }

      
      const data = await setingsApi.updatePaypal({
        ...this.info,
      });
      
      this.$router.push("/settings/config-settings");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>
